import { Routes } from '@angular/router';
import {ThankYouComponent} from "./application/components/thank-you/thank-you.component";

export const routes: Routes = [

  {
    path: "",
    // component: ThankYouComponent
    loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule)
  },
  {
    path: ":brand",
    // component: ThankYouComponent
    loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule)
  },

];
